<template>
  <div>
    <b-form
      id="searchForm"
      method="GET"
      @submit.prevent="updateSearchResults"
    >
      <b-row>
        <!-- <b-col>
          <b-form-select
            id="week-end"
            v-model="currentSearchCriteria.selectedWeekEnd"
            name="week-end"
            :value="currentSearchCriteria.selectedWeekEnd"
            @change="updateSearchResults()"
          >
            <b-form-select-option
              value="W/e xx/xx/xx"
            >
              W/e xx/xx/xx
            </b-form-select-option>
          </b-form-select>
        </b-col> -->
        <b-col
          v-if="!limitToApplicants"
          cols="2"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="currentSearchCriteria.selectedStatus"
              name="status"
              :value="currentSearchCriteria.selectedStatus"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="ALL"
              >
                All
              </b-form-select-option>
              <b-form-select-option
                value="ACTIVE"
              >
                Active
              </b-form-select-option>
              <b-form-select-option
                value="INACTIVE"
              >
                Inactive
              </b-form-select-option>
              <!-- <b-form-select-option
                value="APPROVED"
              >
                Approved
              </b-form-select-option> -->
              <b-form-select-option
                value="ACCEPTED"
              >
                Accepted
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!limitToApplicants"
        >
          <b-form-group
            label="Client last worked for"
            label-for="client"
          >
            <b-form-select
              id="client"
              v-model="currentSearchCriteria.selectedClient"
              name="client"
              :value="currentSearchCriteria.selectedClient"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="ALL"
              >
                All
              </b-form-select-option>
              <b-form-select-option
                v-for="client in searchClients"
                :key="client.ent_id"
                :v-bind="client.ent_id"
                :value="client.ent_id"
              >
                {{ client.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Trade"
            label-for="trade"
          >
            <b-form-select
              id="trade"
              v-model="currentSearchCriteria.selectedTrade"
              name="trade"
              :value="currentSearchCriteria.selectedTrade"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="ALL"
              >
                All
              </b-form-select-option>
              <b-form-select-option
                v-for="trade in searchTrades"
                :key="trade.trade_id"
                :v-bind="trade.trade_id"
                :value="trade.trade_id"
              >
                {{ trade.trade }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            :label="notWorkedSinceLabel"
            label-for="not-worked-since-date"
          >
            <b-form-datepicker
              id="not-worked-since-date"
              v-model="currentSearchCriteria.selectedLastWorked"
              :value="currentSearchCriteria.selectedLastWorked"
              selected-variant="primary"
              show-decade-nav
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              placeholder=""
              @input="updateSearchResults()"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="limitToApplicants"
          cols="2"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="currentSearchCriteria.selectedStatus"
              name="status"
              :value="currentSearchCriteria.selectedStatus"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="ALL"
              >
                All
              </b-form-select-option>
              <b-form-select-option
                value="NEW"
              >
                NEW
              </b-form-select-option>
              <b-form-select-option
                value="REJECTED"
              >
                REJECTED
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col />
        <b-col
          v-if="!limitToApplicants"
        >
          <div class="scl-button-alignment">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="downloadOperatives"
            >
              <span class="align-middle">Download</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormSelect, BFormSelectOption, BCol, BRow, BFormGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OperativesFilterBar',
  components: {
    BForm,
    BCol,
    BRow,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  props: {
    limitToApplicants: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      notWorkedSinceLabel: 'Not worked since',
    }
  },
  computed: {
    ...mapGetters({ operativeSearchCriteria: 'app/getOperativeSearchCriteria' }),
    ...mapGetters({ applicantSearchCriteria: 'applicants/getApplicantSearchCriteria' }),
    ...mapGetters({ operativeSearchparams: 'app/getOperativeSearchCriteriaAsParams' }),
    ...mapGetters({ applicantSearchparams: 'applicants/getApplicantSearchCriteriaAsParams' }),
    ...mapGetters({ searchClients: 'app/getSearchClients' }),
    ...mapGetters({ searchTrades: 'app/getTrades' }),

    currentSearchCriteria() {
      if (this.limitToApplicants) return this.applicantSearchCriteria

      return this.operativeSearchCriteria
    },
  },
  created() {
    this.$store.dispatch('app/getTrades')
    if (this.limitToApplicants) {
      this.notWorkedSinceLabel = 'Start on or before'
    }
    this.updateSearchResults()
  },
  methods: {
    updateSearchResults() {
      if (this.limitToApplicants) {
        this.$store.dispatch('applicants/getApplicantsList', this.applicantSearchparams)
      } else {
        this.$store.dispatch('app/getOperativesList', this.operativeSearchparams)
      }
    },
    downloadOperatives() {
      this.$store.dispatch('app/downloadOperativesList', this.operativeSearchparams)
    },
  },
}
</script>

<style scoped>
.scl-button-alignment {
  margin-top: 1.5rem;
  float: right;
}
</style>
