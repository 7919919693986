<template>
  <div class="text-center">
    <b-badge
      :variant="statusVariant"
    >
      {{ statusText }}
    </b-badge>
  </div>
</template>
<script>

import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'OperativeStatusComponent',
  components: {
    BBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusVariant() {
      if (this.status === 'ACTIVE') return 'light-primary'
      if (this.status === 'INACTIVE') return 'light-secondary'
      if (this.status === 'NEW') return 'light-primary'
      if (this.status === 'REJECTED') return 'light-secondary'
      if (this.status === 'SUBMITTED') return 'light-dark'
      if (this.status === 'ACCEPTED') return 'light-success'

      return 'light-warning'
    },
    statusText() {
      if (this.status === null || this.status === '') return 'UNKNOWN'

      return this.status
    },
  },
}
</script>

<style scoped>

</style>
