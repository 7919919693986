<template>
  <div>
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-md-left mr-50">Show</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="md"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="2"
        class="my-1"
      />
      <b-col
        md="4"
        class="my-1"
      >
        <b-form-group
          label="Search"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :busy.sync="busyState"
          :per-page="perPage"
          :current-page="currentPage"
          :items="limitToApplicants ? applicantList : operativesList"
          :fields="limitToApplicants ? applicantFields : operativeFields"
          :sort-compare="sortCompare"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter-ignored-fields="filterIgnoredFields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="item => $set(item, '_showDetails', !item._showDetails)"
        >
          <!-- :filter-included-fields="filterOn" -->
          <!-- :sort-compare="sortCompare" Not working -->
          <template #cell(sageid)="data">
            {{ getSageID(data.item) }}
          </template>

          <template
            v-slot:cell(firstname)="{ value, item }"
            data-html="true"
          >
            <router-link
              v-if="limitToApplicants"
              :to="{ path: '/applicantedit?operativeid=' + item.ent_id }"
            >
              {{ value }}
            </router-link>
            <router-link
              v-else
              :to="{ path: '/operativeedit?operativeid=' + item.ent_id }"
            >
              {{ value }}
            </router-link>
          </template>

          <template #cell(trade)="data">
            {{ getTradeById(data.item.trade_id) }}
          </template>

          <template #head(status)="data">
            <div class="text-center ml-0 mr-0">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template
            v-if="limitToApplicants"
            #cell(availability)="data"
          >
            {{ getApplicantAvailability(data.item) }}
          </template>
          <template #cell(status)="data">
            <operativeStatusComponent :status="data.value" />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import operativeStatusComponent from '@/components/operatives/ui/OperativeStatusComponent.vue'
import jwt from '@/auth/jwt/sclUseJwt'
import { mapGetters } from 'vuex'

export default {
  name: 'ListOperativesTable',
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    operativeStatusComponent,
  },
  props: {
    limitToApplicants: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      perPage: 25,
      pageOptions: [3, 5, 10, 25, 50, 100],
      currentPage: 1,
      rowCount: 1,
      busyState: false,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      // filterOn: ['firstname', 'lastname', 'sage_id', 'name', 'email'],
      filterIgnoredFields: ['operative'],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      applicantFields: [
        { key: 'firstname', label: 'Firstname', sortable: true },
        { key: 'lastname', label: 'Lastname', sortable: true },
        { key: 'trade', label: 'Trade', sortable: true },
        { key: 'postcode', label: 'Postcode', sortable: true },
        { key: 'mobphone', label: 'Mobile', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'availability', label: 'Start Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      operativeFields: [
        { key: 'sageid', label: 'ID', sortable: true },
        { key: 'firstname', label: 'Firstname', sortable: true },
        { key: 'lastname', label: 'Lastname', sortable: true },
        { key: 'trade', label: 'Trade', sortable: true },
        { key: 'mobphone', label: 'Mobile', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
    }
  },
  computed: {
    ...mapGetters({ applicantList: 'applicants/getApplicantsForCurrentClient' }),
    ...mapGetters({ operativesList: 'app/getOperativesForCurrentClient' }),
    ...mapGetters({ operativeSearchparams: 'app/getOperativeSearchCriteriaAsParams' }),
    ...mapGetters({ applicantSearchparams: 'applicants/getApplicantSearchCriteriaAsParams' }),
    ...mapGetters({ trades: 'app/getTrades' }),

    sortOptions() {
      // Create an options list from our fields
      if (this.limitToApplicants) {
        return this.applicantFields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      }

      return this.operativeFields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.currentSearchCriteria.limitToApplicants = this.limitToApplicants
    // this.updateSearchResults()
  },
  methods: {
    getSageID(row) {
      if (row.operativedetails[0] === undefined) return ''

      return row.operativedetails[0].sage_id
    },
    getTradeById(tradeId) {
      if (this.trades === undefined) return ' '

      const tradeIndex = this.trades.findIndex(trade => trade.trade_id === tradeId)
      if (tradeIndex < 0) return ' '

      return this.trades[tradeIndex].trade
    },
    getApplicantAvailability(row) {
      console.log(row)
      if (row.operativedetails[0] === undefined) return ''

      return row.operativedetails[0].availability
    },
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      let a = aRow[key] // or use Lodash `_.get()`
      let b = bRow[key]
      this.sortDesc = sortDesc
      this.formatter = formatter

      if (key === 'sageid') {
        if (aRow.operativedetails[0] === undefined) return 0
        a = aRow.operativedetails[0].sage_id
        b = bRow.operativedetails[0].sage_id
      } else if (key === 'firstname') {
        a = aRow.firstname
        b = bRow.firstname
      } else if (key === 'status') {
        a = aRow.status
        b = bRow.status
      } else if (key === 'trade') {
        a = this.getTradeById(aRow.trade_id)
        b = this.getTradeById(bRow.trade_id)
      }

      if (
        (typeof a === 'number' && typeof b === 'number')
         || (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }
      // Otherwise stringify the field data and use String.prototype.localeCompare
      return this.toAString(a).localeCompare(this.toAString(b), compareLocale, compareOptions)
    },

    // Helper function to stringify the values of an Object
    toAString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      }

      return String(value)
    },
    updateSearchResults() {
      this.busyState = true

      let urlType = ''
      let params = null
      if (this.limitToApplicants) {
        urlType = 'applicants'
        params = this.applicantSearchparams
        this.$store.commit('applicants/resetApplicantsForCurrentClient')
      } else {
        urlType = 'operatives'
        params = this.operativeSearchparams
        this.$store.commit('app/resetOperativesForCurrentClient')
      }

      jwt.axiosIns.get(`/${urlType}/${params}`)
        .then(response => {
          if (this.limitToApplicants) {
            this.rowCount = this.applicantList.length
            this.$store.commit('applicants/getApplicantsForCurrentClient', JSON.parse(JSON.stringify(response.data)))
          } else {
            this.$store.commit('app/getOperativesForCurrentClient', JSON.parse(JSON.stringify(response.data)))
            this.rowCount = this.operativesList.length
          }
          this.busyState = false
        })
        .catch(e => {
          console.error(e)
          this.busyState = false
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
